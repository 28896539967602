export default [
  {
    path: '/app/services',
    name: 'Services',
    component: () => import('@/views/Admin/Services/Index.vue'),
    meta: {
      pageTitle: 'Servis Kayıtları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'CUSTOMER_SERVICE_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/services/view/:id',
    name: 'ServiceView',
    component: () => import('@/views/Admin/Services/View.vue'),
    meta: {
      pageTitle: 'Servis Kayıtları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/services',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CUSTOMER_SERVICE_LIST',
      action: 'read',
    },
  },
]
