import axiosIns from '@/libs/axios'

const ROOT_URL = '/Clients/data/dashboard'
export default {
  namespaced: true,
  state: {
    dashboard: {
      counters: {
        services: null,
        machines: null,
      },
    },
    filters: {
      sdate: null,
      edate: null,
    },
    loading: false,
  },
  getters: {
    dashboard(state) {
      return state.dashboard
    },
    loading(state) {
      return state.loading
    },
    filters(state) {
      return state.filters
    },
  },
  mutations: {
    SET_DASHBOARD(state, data) {
      state.dashboard = data
    },
    SET_LOADING(state, data) {
      state.loading = data
    },
    RESET_DASHBOARD(state) {
      state.dashboard = {
        counters: {
          services: null,
          machines: null,
        },
      }
    },
  },
  actions: {
    getData({ commit }, params) {
      commit('SET_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DASHBOARD', response.data.data)
          commit('SET_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
