export default [
  {
    path: '/app/machines',
    name: 'Machines',
    component: () => import('@/views/Admin/Machines/CustomerMachines.vue'),
    meta: {
      pageTitle: 'Makina Kartları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'CUSTOMER_MACHINES_LIST',
      action: 'read',
    },
  },
]
