import Vue from 'vue'
import Vuex from 'vuex'

/* Admin:Core */
import abilities from '@/store/Admin/Core/abilities'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app/index'
import profile from './app/profile'
/* Admin:Defines */
/* Admin:App */
import customers from './Admin/App/customers'
import customerMachines from './Admin/App/customer_machines'
import dashboard from './Admin/App/dashboard'
import products from './Admin/App/products'
import services from './Admin/App/services'
import serviceFiles from './Admin/App/service_files'
import serviceMailsend from './Admin/App/service_mailsend'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    profile,
    abilities,
    customers,
    customerMachines,
    dashboard,
    products,
    services,
    serviceFiles,
    serviceMailsend,
  },
  strict: process.env.DEV,
})
