import axiosIns from '@/libs/axios'

const ROOT_URL = '/Clients/data/service_files'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataItem: {
      title: null,
      upload_file: null,
      id_services: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    uploading: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataItem(state) {
      return state.dataItem
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        title: null,
        upload_file: null,
        id_services: null,
      }
    },
  },
  actions: {
    getDataList({ commit }, id) {
      axiosIns
        .post(ROOT_URL, { id })
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
